import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav
} from 'reactstrap';

import TranslateMessage from 'components/common/TranslateMessage/TranslateMessage';
import { AccountCircle, Person, ExitToApp, Menu, Subscriptions } from '@material-ui/icons';
import { getMenuRoutes, getRoutePath } from 'state/route/routeSelector';
import { getAuthUser, getIsMobileApp, isAuthenticated, isGoogleLogin } from 'state/session/sessionSelector';
import { getPageTitle, getPageLogo, getPageFavicon, getLayoutViewPage, getBannerSetting, getLayoutSettingAll } from 'state/search/searchSelector';
import { ActionContext } from 'components/layout/MainContainer';
import LoginModal from 'components/modal/LoginModal';
import LoginEndUserForm from 'views/end-user/form/LoginEndUserForm';
import md5 from 'md5';
import { AUTHEN_TYPES, IMAGE_PATH, LAYOUT_SETTING, OPTION_LOGIN, USER_CONFIG } from 'configs/AppConfig';
import Util from 'utils/Util';
import { Box, makeStyles } from '@material-ui/core';
// import notFoundImage from '../../assets/img/not-found.png';
import { getPageMargin, getServicePath, getViewSettings } from 'state/company/companySelector';
import IconMenuBarButton from 'components/common/Button/IconMenuBarButton';
import { useMedia } from 'react-use';

const PublicAppBar = (props) => {
  const {
    sessionActions: { logout, login },
    authUser,
    pageTitle,
    pageLogo,
    pageFavicon,
    isAuthen,
    layoutViewPage,
    bannerSetting,
    isMobileApp
    // isGoogleLogin
  } = props;

  const [showLoginFormModal, setShowLoginFormModal] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);

  const style = useSelector((state) => state.search.headerSetting) || {};
  const showSubscriptionStatus = useSelector((state) => state.company.showSubscriptionStatus);
  const viewSettings = useSelector(getViewSettings);
  const layoutSettingAll = useSelector(getLayoutSettingAll) || {};
  const servicePath = useSelector(getServicePath);
  const pageMargin = useSelector(getPageMargin);

  const isOpenType = viewSettings.accountType === OPTION_LOGIN.OPEN_TYPE;
  const isHomeAndSearchLayout = layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id;
  const below768 = useMedia('(max-width: 768px)');
  const useStyle = makeStyles(() => (
    {
      header: {
        backgroundColor: `${style.backgroundColor}!important`,
        color: style.color || '#d0d0d0',
        padding: below768 ? 0 : '30px 1.3rem',
        display: below768 ? 'block' : 'flex',
        '& .nav-link': {
          color: `${style.color || '#d0d0d0'}!important`,
          whiteSpace: 'unset',
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        }
      },
      logo: {
        width: 'auto',
        height: '100%',
        minHeight: below768 ? 'unset' : '60px',
        maxWidth: below768 ? '70px' : 'unset',
        maxHeight: '70px',
        objectFit: 'contain'
      },
      logoLayout3: {
        width: 'auto',
        height: '100%',
        minHeight: below768 ? 'unset' : '60px',
        maxWidth: below768 ? '70px' : 'unset',
        maxHeight: '70px',
        objectFit: 'contain'
      },
      logoBox: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'wrap',
        '&::after': below768 ? {} : {
          marginLeft: '1rem',
          marginRight: '1rem',
          borderTop: '4px dotted #ebebeb',
          content: '""',
          flexGrow: 1
        }
      },
      logoBoxLayout3: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'wrap',
        marginLeft: !below768 ? 0 : 10
      },
      dropdownToggle: {
        '&::after': {
          marginLeft: '1rem',
          marginRight: '1rem',
          borderTop: '4px dotted #ebebeb',
          content: isHomeAndSearchLayout ? 'none' : ''
        }
      },
      pageTitle: {
        margin: '0 1rem!important',
        padding: 0,
        color: `${style.color || '#fff'}!important`,
        whiteSpace: 'inherit',
        wordBreak: 'break-word',
        wordWrap: 'break-word'
      },
      layout3: {
        marginTop: !below768 ? 0 : 5,
        marginLeft: `${!below768 ? pageMargin.left : '0'}px`,
        marginRight: `${!below768 ? pageMargin.right : '0'}px`,
        flexWrap: below768 ? 'nowrap' : ' wrap',
        '&.container-fluid': {
          flexWrap: below768 ? 'nowrap' : ' nowrap'
        }
      },
      bannerBox: {
        height: below768 ? bannerSetting.heightBanner * 0.6 : bannerSetting.heightBanner,
        backgroundColor: bannerSetting.backgroundColor,
        color: bannerSetting.textColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: below768 ? bannerSetting.fontSize * 0.6 : bannerSetting.fontSize,
        cursor: 'pointer',
        '& img': {
          objectFit: 'contain',
          height: '100%',
          width: '100%'
        },
        wordBreak: 'break-word',
        textAlign: 'center',
        padding: bannerSetting.typeBanner === 1 ? '0px 10px 0px 10px' : ''
      },
      accountCircleActive: {
        color: '#00B27B'
      },
      iconUserCircle: {
        fontSize: 25
      },
      userName: {
        marginBottom: '3px',
        height: below768 ? '25px' : ''
      },
      navbarMenu: {
        '& .dropdown-menu': {
          position: 'absolute'
        }
      }
    }
  ));

  const classes = useStyle();

  const homeUrl = window.location.origin + '/' + servicePath;
  const history = useHistory();
  const urlUpload = process.env.REACT_APP_UPLOAD_PATH + '/';

  useEffect(() => {
    // Set Favicon
    if (pageFavicon) {
      const favicon = document.getElementById('favicon');
      favicon.href = `${urlUpload}${IMAGE_PATH.FAVICON}${pageFavicon}`;
    }
  }, [pageFavicon]);

  const handleLoginFormSubmit = async (data, authenType) => {
    setShowLoginFormModal(false);
    let request;
    if (authenType === AUTHEN_TYPES.EMAIL) {
      request = {
        password: md5(data.password),
        rememberMe: data.rememberMe,
        email: data.email,
        loginType: AUTHEN_TYPES.EMAIL,
        loginRole: USER_CONFIG.USER.loginType,
        path: data.path
      };
    } else if (authenType === AUTHEN_TYPES.GOOGLE_LOGIN) {
      request = data;
    } else {
      const userTrace = await Util.getUserTrace();
      const ip = userTrace.ip;
      request = {
        ip: ip,
        loginType: AUTHEN_TYPES.IP
      };
    }
    login(request);
  };

  const handleLogout = () => {
    if (!isMobileApp) {
      logout();
    }
  };

  const handleClickOutside = (event) => {
    const accountDropdownToggle = document.getElementById('accountDropdownToggle');
    if (accountDropdownToggle && !accountDropdownToggle.contains(event.target)) {
      setIsOpenProfile(false);
    }
    // if (!accountDropdownToggle && !accountDropdownToggle2) {
    //   setIsOpenProfile(false);
    // }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header>
      <Navbar dark expand={true} className={classes.header}>
        <div className={`container-fluid p-0 ${layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id ? classes.layout3 : ''}`} style={{ justifyContent: 'flex-start' }}>
          <Box className={`${layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id ? classes.logoBoxLayout3 : classes.logoBox}`} >
            <Link to={'/'}>
              {pageLogo && pageLogo !== '' && pageLogo !== 'null'
                ? (
                  <img className={`pr-1 ${layoutViewPage === LAYOUT_SETTING.HOME_LAYOUT_AND_SEARCH_LAYOUT.id ? classes.logoLayout3 : classes.logo}`} width="40px" src={`${urlUpload}${IMAGE_PATH.LOGO}${pageLogo}`} />
                )
                : (
                  <></>
                  // <img className={`pr-1 ${classes.logo}`} width="40px" src={notFoundImage} />
                )
              }
            </Link>
            {
              pageTitle && <NavbarBrand href={homeUrl} className={classes.pageTitle}>
                <span>{pageTitle}</span>
              </NavbarBrand>
            }
          </Box>
          {
            (isOpenType || isMobileApp)
              ? null
              : authUser && isAuthen
                ? (
                  <Collapse isOpen={true} navbar style={{ flexGrow: 'unset', display: 'flex', minWidth: 100 }}>
                    <Nav className={`ml-auto ${classes.navbarMenu}`}navbar onClick={() => setIsOpenProfile(!isOpenProfile)} style={ isHomeAndSearchLayout ? { display: 'flex', alignItems: 'center', flexDirection: below768 ? 'row' : '' } : {}}>
                      <UncontrolledDropdown nav inNavbar isOpen={isOpenProfile}>
                        <DropdownToggle id='accountDropdownToggle' nav caret className={`cs-dropdown-toggle text-right ${classes.dropdownToggle}`} >
                          {
                            isHomeAndSearchLayout
                              ? <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span className={classes.userName}>{authUser.name ? `${authUser.name}` : `${authUser.fullName}`}</span>
                                <i className={`far fa-user-circle ml-2 ${classes.iconUserCircle} ${isOpenProfile ? classes.accountCircleActive : ''}`}/>
                              </div>
                              : <>
                                <span>{authUser.name ? `${authUser.name}` : `${authUser.fullName}`}</span>
                                <AccountCircle className="ml-2" />
                              </>
                          }
                        </DropdownToggle>
                        <DropdownMenu right>
                          {
                            authUser.roleLevel !== 4 ? (
                              <DropdownItem className="cs-dropdown-item" onClick={() => {
                                history.push('/profile');
                              }}>
                                <Person className="mr-2" />
                                <TranslateMessage id="label.profile" />
                              </DropdownItem>
                            ) : null
                          }
                          {
                            isHomeAndSearchLayout && showSubscriptionStatus
                              ? <DropdownItem className="cs-dropdown-item" onClick={() => {
                                history.push('/subscription');
                              }}>
                                <Subscriptions className="mr-2" />
                                <TranslateMessage id="label.subscription" />
                              </DropdownItem>
                              : null
                          }
                          <DropdownItem className="cs-dropdown-item" onClick={() => { handleLogout() }}>
                            <ExitToApp className="mr-2" />
                            <TranslateMessage id="label.logout" />
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <div style={{ margin: '0 0.5rem' }}>
                        {
                          isHomeAndSearchLayout
                            ? (
                              layoutSettingAll.isFullScreenSearch
                                ? <IconMenuBarButton
                                  // label={<TranslateMessage id="label.search" />}
                                />
                                : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                  <Menu onClick={() => history.push('/search')}/>
                                  {/* <TranslateMessage id="label.search" /> */}
                                </div>
                            )
                            : null
                        }
                      </div>
                    </Nav>
                  </Collapse>
                )
                : <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className={'font-weight-bold pointer text-right'} onClick={() => setShowLoginFormModal(true)} style={{ margin: '0 0.5rem' }}>
                    <TranslateMessage id="menu.login" />
                  </div>
                  {
                    isHomeAndSearchLayout
                      ? (
                        <div style={{ margin: '0 0.5rem' }}>
                          {
                            layoutSettingAll.isFullScreenSearch
                              ? <IconMenuBarButton
                                label={null}
                              />
                              : <>
                                <Menu style={{ cursor: 'pointer' }} onClick={() => history.push('/search')}/>
                                {/* <TranslateMessage id="label.search" /> */}
                              </>
                          }
                        </div>
                      )
                      : null
                  }
                </div>
          }
        </div>
      </Navbar>
      <LoginModal
        isOpen={showLoginFormModal}
        toggle={() => setShowLoginFormModal(false)}
        size="md"
        backdrop={'static'}
      >
        <LoginEndUserForm
          onCancel={() => setShowLoginFormModal(false)}
          onSubmit={handleLoginFormSubmit}
        />
      </LoginModal>
    </header>
  );
};

PublicAppBar.defaultProps = {};

PublicAppBar.propTypes = {
  location: PropTypes.object,
  sessionActions: PropTypes.object.isRequired,
  menuRoutes: PropTypes.array.isRequired,
  routePath: PropTypes.string.isRequired,
  authUser: PropTypes.object,
  isAuthen: PropTypes.bool,
  pageTitle: PropTypes.any,
  pageLogo: PropTypes.any,
  pageFavicon: PropTypes.any,
  isGoogleLogin: PropTypes.bool,
  bannerSetting: PropTypes.any,
  layoutViewPage: PropTypes.number,
  isMobileApp: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    menuRoutes: getMenuRoutes(state),
    routePath: getRoutePath(state),
    authUser: getAuthUser(state),
    pageTitle: getPageTitle(state),
    pageLogo: getPageLogo(state),
    pageFavicon: getPageFavicon(state),
    isAuthen: isAuthenticated(state),
    isGoogleLogin: isGoogleLogin(state),
    bannerSetting: getBannerSetting(state),
    layoutViewPage: getLayoutViewPage(state),
    isMobileApp: getIsMobileApp(state)
  };
};

export default connect(mapStateToProps)(
  props => (
    <ActionContext.Consumer>
      {({ sessionActions }) => (
        <PublicAppBar
          {...props}
          sessionActions={sessionActions}
        />
      )}
    </ActionContext.Consumer>
  )
);
